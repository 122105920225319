<template>
  <div class="reserved-area-page">
    <div class="container">
      <div class="d-xl-flex">
        <div class="reserved-area-page-column">
          <reservedAreaMenu/>
        </div>
        <div class="reserved-area-page-column">
          <form class="reserved-area-plans-form" v-if="!showPayments" @submit="handleChangePlan">
            <div class="row">
              <div class="col-12 reserved-area-form-column">
                <div class="reserved-area-form-group-wrapper">
                  <p class="section-title reserved-area-form-title">PLANO ATIVO</p>
                  <div class="reserved-area-form-group">
                    <div class="plan-item-wrapper blocked" v-if=" activePlan.planId == null ">
                      <div class="plan-item d-flex flex-column flex-lg-row justify-content-between align-items-center">
                        <div>
                          <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                            <div class="plan-item-icon">
                              <lockOpen/>
                            </div>
                            <p class="plan-item-title">GRATUÍTO</p>
                          </div>
                          <p class="plan-item-subtitle">Acesso a uma seleção de treinos e execícios disponiveis na plataforma</p>
                        </div>

                        <div class="plan-item-price">
                          0€
                          <span>/ Mês</span>
                        </div>
                      </div>
                    </div>
                    <div class="plan-item-wrapper blocked" v-for="(item,index) in subscriptions" :key="index" v-if=" activePlan.planId != null && item.id == activePlan.planId ">
                      <div class="plan-item d-flex flex-column flex-lg-row justify-content-between align-items-center">
                        <div>
                          <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                            <div class="plan-item-icon">
                              <lock/>
                            </div>
                            <p class="plan-item-title">{{ item.name }}</p>
                          </div>
                          <div class="plan-item-subtitle" v-html="item.description"></div>
                        </div>


                        <div class="plan-item-price-wrapper" v-if="item.subTitle != ''">
                          <div class="d-flex justify-content-center align-items-center">
                            <div class="plan-item-price smaller">
                              {{ item.normalPrice }}
                              <span>/ Expira</span>
                            </div>
                          </div>
                          <p class="plan-item-price-note">{{ item.subTitle }}</p>
                        </div>
                        <div class="plan-item-price" v-else>
                          {{ item.normalPrice }}
                          <span>/ Mês</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 reserved-area-form-column">
                <div class="reserved-area-form-group-wrapper">
                  <p class="section-title reserved-area-form-title">OUTROS PLANOS</p>
                </div>
              </div>
              <div class="col-12 col-lg-4 col-xl-12 col-xxl-4 reserved-area-form-column" v-for="(item,index) in subscriptions" :key="index">
                <div class="reserved-area-form-group-wrapper h-100">
                  <div class="reserved-area-form-group h-100">
                    <div class="plan-item-wrapper">
                      <label>
                        <input type="radio" name="payment-method" :value="item.id" v-model="plan" @change="planChange">
                        <div class="plan-item d-flex flex-column justify-content-start align-items-center">
                          <div class="plan-item-icon">
                            <lock/>
                          </div>
                          <p class="plan-item-title">{{ item.name }}</p>
                          <div class="plan-item-subtitle" v-html="item.description"></div>
                          <div class="plan-item-price" v-if="item.id == 16">
                            {{ item.normalPrice }}
                            <span>/ Mês</span>
                          </div>

                          <div class="plan-item-price-wrapper" v-else>
                            <div class="d-flex justify-content-center align-items-center">
                              <div class="plan-item-price">{{ item.normalPrice }}</div>
                              <div class="plan-item-price-discount" v-if="!!item.discount_ammount">{{ item.discount_ammount }}</div>
                            </div>
                            <p class="plan-item-price-note">{{ item.subtitle }}</p>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reserved-area-form-btn-wrapper d-flex justify-content-center align-items-center">
              <button type="submit" class="reserved-area-form-btn generic-btn " :class="[formActive ? 'red-gradient' : 'grey-gradient']">Alterar Plano</button>
            </div>
          </form>
          <div class="reserved-area-payments" v-if="showPayments">
            <button type="button" class="header-arrow d-none d-lg-flex" @click="backToPlans">
              <chevronLeft/>
            </button>
            <div class="auth-form-heading">
              <p class="auth-form-title">PAGAMENTO</p>
            </div>
            <FormulateForm class="reserved-area-payments-form" name="reserved-area-payments-form" v-model="values" @submit="submitNewPlan">
              <div class="auth-faqs-wrapper">
                <div class="auth-faqs-item faqs-item active">
                  <button type="button" class="faqs-item-btn auth-faqs-item-btn d-flex justify-content-between align-items-center" @click="toggleAccordion">
                    <p>Opção de Pagamento</p>
                    <div class="auth-faqs-item-btn-icon">
                      <chevronDown/>
                    </div>
                  </button>
                  <div class="auth-faqs-item-content-wrapper">
                    <div class="auth-faqs-item-content faqs-content">
                      <div class="auth-faqs-radio-wrapper" v-for="(item,index) in subscriptions" :key="index">
                        <label>
                          <input type="radio" name="payment-option" v-model="plan" :value="item.id">
                          <div class="auth-faqs-radio-btn d-flex justify-content-lg-between align-items-center">
                            <div class="flex-1 d-md-flex justify-content-between align-items-center">
                              <p class="auth-faqs-radio-btn-title">{{ item.name }}</p>
                              <div class="d-flex justify-content-start align-items-center">
                                <p class="auth-faqs-radio-value">{{ item.normalPrice }} <span>(5,09€/mês)</span></p>
                                <p class="auth-faqs-radio-discount" v-if="!!item.discount_ammount">{{ item.discount_ammount }}</p>
                              </div>
                            </div>
                            <div class="auth-faqs-radio-checkbox"></div>
                          </div>
                        </label>
                      </div>
                      <!--<div class="add-voucher-wrapper">
                        <button class="add-voucher-btn d-flex justify-content-start align-items-center" @click="openVoucherForm">
                          <p>Adicionar Voucher Promocional</p>
                          <div class="add-voucher-btn-icon">
                            <plus/>
                          </div>
                        </button>
                        <form class="add-voucher-form">
                          <div class="d-flex justify-content-start align-items-center">
                            <div class="add-voucher-input">
                              <input type="text">
                            </div>
                            <button type="button" class="generic-btn red-gradient smaller">Validar</button>
                          </div>
                        </form>
                      </div>-->
                    </div>
                  </div>
                </div>
                <div class="auth-faqs-item faqs-item">
                  <button type="button" class="faqs-item-btn auth-faqs-item-btn d-flex justify-content-between align-items-center" @click="toggleAccordion">
                    <p>Método de Pagamento</p>
                    <div class="auth-faqs-item-btn-icon">
                      <chevronDown/>
                    </div>
                  </button>
                  <div class="auth-faqs-item-content-wrapper">
                    <div class="auth-faqs-item-content faqs-content">
                      <div class="auth-faqs-radio-wrapper">
                        <label>
                          <input type="radio" name="payment-method" v-model="paymentMethod" value="mb">
                          <div class="auth-faqs-radio-btn d-flex justify-content-lg-between align-items-center">
                            <div class="flex-1 d-flex justify-content-start align-items-center">
                              <div class="auth-faqs-radio-btn-image d-flex justify-content-center align-items-center">
                                <img src="@/assets/img/multibanco-logo.png" alt="">
                              </div>
                              <p class="auth-faqs-radio-btn-title">Multibanco</p>
                            </div>
                            <div class="auth-faqs-radio-checkbox"></div>
                          </div>
                        </label>
                      </div>
                      <div class="auth-faqs-radio-wrapper">
                        <label>
                          <input type="radio" name="payment-method" v-model="paymentMethod" value="cc">
                          <div class="auth-faqs-radio-btn d-flex justify-content-lg-between align-items-center">
                            <div class="flex-1 d-flex justify-content-start align-items-center">
                              <div class="auth-faqs-radio-btn-image d-flex justify-content-center align-items-center">
                                <img src="@/assets/img/visa.png" alt="">
                              </div>
                              <p class="auth-faqs-radio-btn-title">Visa</p>
                            </div>
                            <div class="auth-faqs-radio-checkbox"></div>
                          </div>
                        </label>
                      </div>
                      <div class="auth-faqs-radio-wrapper">
                        <label>
                          <input type="radio" name="payment-method" v-model="paymentMethod" value="mw">
                          <div class="auth-faqs-radio-btn d-flex justify-content-lg-between align-items-center">
                            <div class="flex-1 d-flex justify-content-start align-items-center">
                              <div class="auth-faqs-radio-btn-image d-flex justify-content-center align-items-center">
                                <img src="@/assets/img/mb-way.png" alt="">
                              </div>
                              <p class="auth-faqs-radio-btn-title">MB Way</p>
                            </div>
                            <div class="auth-faqs-radio-checkbox"></div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="auth-faqs-item faqs-item">
                  <button type="button" class="faqs-item-btn auth-faqs-item-btn d-flex justify-content-between align-items-center" @click="toggleAccordion">
                    <p>Dados de Faturação</p>
                    <div class="auth-faqs-item-btn-icon">
                      <chevronDown/>
                    </div>
                  </button>
                  <div class="auth-faqs-item-content-wrapper">
                    <div class="auth-faqs-item-content faqs-content">
                      <div class="box-form-group">
                        <div class="row box-form-row">
                          <div class="col-12 col-lg-6 box-form-column">
                            <div class="form-field">
                              <FormulateInput type="text" name="name" validation="required" :errors="errors" v-model="firstName" placeholder="Insira o seu primeiro nome" label="Primeiro Nome" />
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 box-form-column">
                            <div class="form-field">
                              <FormulateInput type="text" name="last_name" validation="required" v-model="lastName" placeholder="Insira o seu último nome" label="Último Nome" />
                            </div>
                          </div>
                          <div class="col-12 box-form-column">
                            <div class="form-field">
                              <FormulateInput type="text" name="address" validation="required" v-model="billingAddress" placeholder="Insira a sua morada" label="Morada" />
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 box-form-column">
                            <div class="form-field">
                              <FormulateInput type="text" name="zipCode" validation="required" v-model="zipCode" placeholder="0000-000" label="Código-Postal" />
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 box-form-column">
                            <div class="form-field">
                              <FormulateInput type="text" name="city" validation="required" v-model="city" placeholder="Insira a sua cidade" label="Cidade" />
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 box-form-column">
                            <div class="form-field">
                              <FormulateInput type="select" name="country" validation="required" v-model="country" placeholder="Selecione o seu país" label="País" :options="this.$root.countries" />
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 box-form-column">
                            <div class="form-field">
                              <FormulateInput type="text" name="vat" validation="required" v-model="nif" placeholder="Insira o seu NIF" label="NIF" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-message-response-wrapper message-error" v-if="showMessage && !submitted" v-html="formResponseMessage"></div>
              <button type="submit" class="generic-btn grey-gradient auth-form-submit">Finalizar Subscrição</button>
            </FormulateForm>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reservedAreaMenu from "@/components/reserved-area/reserved-area-menu.vue";
import lock from "@/components/svg/lock2.vue";
import lockOpen from "@/components/svg/lock2-open.vue";
import chevronDown from "@/components/svg/chevron-down.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";
import plus from "@/components/svg/plus.vue";
import axios from "axios";
import qs from "qs";
import {EventBus} from "@/event-bus";

export default {
  name: "reserved-area-plans",
  data() {
    return {
      values: {},
      lang: this.$root.siteLang,
      showPayments: false,
      formActive: false,
      plan: '',
      paymentMethod: '',
      paymentOption: '',
      showMessage: false,
      formResponseMessage: '',
      submitted: false,
      subscriptions: [],
      errors: [],
      activePlan: '',
      firstName: '',
      lastName: '',
      billingAddress: '',
      zipCode: '',
      city: '',
      country: '',
      nif: '',
    };
  },
  components: {
    reservedAreaMenu,
    lock,
    lockOpen,
    chevronDown,
    chevronLeft,
    plus
  },
  metaInfo () {
    return {
      title: this.$root.translations.definicoes,
    }
  },
  methods: {
    toggleAccordion (e) {
      let clickedItem = e.currentTarget.closest('.auth-faqs-item');
      let items = Array.from(document.querySelectorAll('.auth-faqs-item'));

      if ( clickedItem.classList.contains('active') ) {
        clickedItem.classList.remove('active');
      } else {
        items.forEach(node => {
          node.classList.remove('active');
        });

        clickedItem.classList.add('active');
      }
    },
    openVoucherForm: function(e) {
      e.preventDefault();
      let wrapper = document.querySelector('.add-voucher-wrapper');

      if ( wrapper.classList.contains('active') ) {
        wrapper.classList.remove('active');
      } else {
        wrapper.classList.add('active');
      }
    },
    handleChangePlan: function(e) {
      e.preventDefault();
        if ( this.plan != '' ) {
          this.showPayments = true;
        }
    },
    backToPlans: function() {
      this.showPayments = false;
    },
    planChange: function() {
      if ( this.plan != '' ) {
        this.formActive = true;
      }
    },
    submitNewPlan: function() {
      let authKey = window.$cookies.get("authKey");

      const requestBody = {
        product_id: this.plan,
        payment_method: this.paymentMethod,
        firstName: this.firstName,
        lastName: this.lastName,
        address: this.billingAddress,
        zipCode: this.zipCode,
        city: this.city,
        country: this.country,
        nif: this.nif,
      };

      axios
          .post(process.env.VUE_APP_API_URL + 'order/subscription', qs.stringify(requestBody),{
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            window.location.href = response.data.model.data.REDIRECTURL;
          })
          .catch((e) => {
            this.errors.push(e);
          }).finally((e) => {});
    },
    setData() {
      if (Object.keys(this.$root.userInfo.billing_info).length) {
        this.firstName = this.$root.userInfo.billing_info.name;
        this.lastName = this.$root.userInfo.billing_info.last_name;
        this.country = this.$root.userInfo.billing_info.country_id;
        this.city = this.$root.userInfo.billing_info.city;
        this.phone = this.$root.userInfo.billing_info.phone;
        this.zipCode = this.$root.userInfo.billing_info.postal_code;
        this.billingAddress = this.$root.userInfo.billing_info.address;
        this.nif = this.$root.userInfo.billing_info.vat;
      }
    }
  },
  async created() {

    this.setData();
    let authKey = window.$cookies.get("authKey");
    this.$root.pageTitle = this.$root.translations.definicoes;

    axios
        .get(process.env.VUE_APP_API_URL + 'order/subscription',{
          headers: {
            Authorization: 'Bearer ' + authKey
          }
        })
        .then((response) => {
          this.subscriptions = response.data.model;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});

    axios
        .get(process.env.VUE_APP_API_URL + 'order/active-plan',{
          headers: {
            Authorization: 'Bearer ' + authKey
          }
        })
        .then((response) => {
          this.activePlan = response.data.model;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});

     await this.$root.getCustomer();
  }
}
</script>